<template>
    <KCourse loader-class="MBcont" course-id="9" title="Эмоции" :items="items">
        <div class="MBtextcont">       
            <p>А вы знали, что Удивление — это самая быстротечная из всех эмоций? Она длится всего несколько секунд, а воспоминаний потом чуть ли не на всю жизнь... Правда, забавно!?</p>
        </div>

        <VideoView video-id="092d4c8345e4405d92a05e531a391666"/>

        <div class="MBtextcont">
            <a href="/files/Emotions/4Surprise.pdf" target="_blank" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/miniland/check-list4.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист по удивлению</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>
        </div>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage,
        },
        data() {
        return {
            items: chapters
        }
        }
    }
</script>

<style>

</style>